import React from 'react';

import {Box, Flex, LayoutGrid} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {HEADER_NAV_HEIGHT} from 'src/utils/measurements';

const {H4, P2} = Typography;

const Maintenance: React.FC = () => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid
        height={HEADER_NAV_HEIGHT.responsive.map(navHeight =>
          navHeight !== null ? `calc(100vh - ${navHeight})}` : null,
        )}
        alignItems="center"
      >
        <Flex gridColumn="1/end" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center">
          <H4 color="cream" mb="12px">
            We&#39;ll be back up soon
          </H4>
          <P2 color="cream">
            We&#39;re doing some maintenance to the site. If you&#39;re looking for Core or event links, check your
            email.
          </P2>
        </Flex>
      </LayoutGrid>
    </Box>
  );
};

export default Maintenance;
